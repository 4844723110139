<template>
  <div class="expenses__wrapper">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading" class="expenses" id="expenses-table">
      <StickyHeader>
        <div
          ref="tableHeaderRef"
          class="expenses__head"
          :style="{
            'grid-template-columns': `${firstColumnWidth}px repeat(${
              getSelectedInterval.amount + 1
            }, 50px)`,
          }"
        >
          <ExpensesHeadCell
            :isItEmptyCell="true"
            :isTimerCell="isTimerActive && !readonly"
            :numberOfColumn="0"
          />
          <ExpensesHeadCell :isItSumCell="true" :numberOfColumn="1" />
          <ExpensesHeadCell
            :key="'header-day-' + n + 1"
            v-for="n in getSelectedInterval.amount"
            :numberOfColumn="n + 1"
          />
        </div>
      </StickyHeader>
      <ExpensesRow
        v-if="!readonly"
        :style="{
          'border-left-color': '#263238',
        }"
        :isNonEditable="true"
      >
        <ExpensesCell
          :numberOfColumn="0"
          style="
            background-color: var(--primary-bg-color);
            border-left: 1px solid var(--primary-bg-color);
            margin-top: -1px;
            height: calc(100% + 1px);
            width: calc(100% + 1px);
            margin-left: 0;
            border-left: none;
          "
        />
        <ExpensesCell
          :numberOfColumn="1"
          :data="'Σ'"
          :is-head-cell="true"
          style="
            justify-content: center;
            background-color: var(--primary-bg-color);
            color: #ffffff;
            margin-top: -1px;
            height: calc(100% + 1px);
            border-left: none;
          "
        />
        <ExpensesCell
          v-for="n in intervalData.amount"
          :key="'tracked-hours-' + n"
          :numberOfColumn="n + 1"
          :isItLastCell="n === intervalData.amount"
          :data="getTrackedHours(n)"
          style="justify-content: center"
        />
      </ExpensesRow>
      <ExpensesRow v-if="!readonly" :isEditable="false">
        <ExpensesCell
          :is-general-cell="true"
          :hoursRatio="getAllHoursRatio"
          :numberOfColumn="0"
        />
        <ExpensesCell
          v-for="n in intervalData.amount + 1"
          :key="n"
          :numberOfColumn="n"
          :is-general-cell="true"
          :isItLastCell="n - 1 === intervalData.amount"
        />
      </ExpensesRow>
      <template
        v-for="(profilesData, profilesIndex) of readonly && profilesModeOn
          ? expensesList
          : [{}]"
      >
        <SpoilerRow
          :key="'pre-profile-' + profilesData.id + `-${profilesIndex}`"
          v-if="readonly && profilesModeOn"
          :rowData="{
            sum_plan_hours: profilesData.customers.reduce(
              (acc, customer) => acc + customer.sum_plan_hours,
              0
            ),
            sum_actual_hours: profilesData.customers.reduce(
              (acc, customer) => acc + customer.sum_actual_hours,
              0
            ),
            name: `${profilesData.last_name} ${profilesData.first_name}`,
          }"
          :spoilerType="'pre-profile'"
          :spoilerName="`${
            readonly && profilesModeOn ? `${profilesData.id}` : ''
          }`"
          :customCellsData="(n) => getTrackedHours(n, profilesData.id)"
        />
        <template
          v-for="customerData of profilesModeOn
            ? profilesData.customers
            : expensesList"
        >
          <SpoilerRow
            :key="'customer-' + customerData.id + `-${profilesData.id}`"
            :rowData="customerData"
            :spoilerType="'customer'"
            :spoilerName="`${
              readonly && profilesModeOn ? `${profilesData.id}--` : ''
            }${customerData.name}`"
          />
          <template v-for="projectData of customerData.projects">
            <SpoilerRow
              :key="'project-' + projectData.id + `-${profilesData.id}`"
              :rowData="projectData"
              :spoilerType="'project'"
              :spoilerName="`${
                readonly && profilesModeOn ? `${profilesData.id}--` : ''
              }${customerData.name}--${projectData.name}`"
            />
            <template v-for="assignData of projectData.assignments">
              <SpoilerRow
                :key="'activity-' + assignData.id + `-${profilesData.id}`"
                v-if="!!assignData.activity"
                :rowData="{
                  ...assignData,
                  name: assignData.activity.activity,
                }"
                :spoilerType="'activity'"
                :spoilerName="`${
                  readonly && profilesModeOn ? `${profilesData.id}--` : ''
                }${customerData.name}--${projectData.name}--${
                  assignData.activity.activity
                }`"
              />
              <template
                v-for="(
                  subAssignData, subAssignIndex
                ) of assignData.sub_assignments"
              >
                <SpoilerRow
                  :key="'profile-' + subAssignData.id + `-${subAssignIndex}`"
                  v-if="readonly && !profilesModeOn"
                  :rowData="{
                    ...subAssignData,
                    name: subAssignData.profile.full_name,
                  }"
                  :spoilerType="'profile'"
                  :spoilerName="`${
                    readonly && profilesModeOn ? `${profilesData.id}--` : ''
                  }${customerData.name}--${projectData.name}--${
                    assignData.activity.activity
                  }--${subAssignData.profile.full_name}`"
                />
                <template v-for="task of subAssignData.tasks">
                  <ExpensesRow
                    v-if="isTaskVisible(task.id)"
                    :parentSpoilerName="`${
                      readonly && profilesModeOn ? `${profilesData.id}--` : ''
                    }${customerData.name}--${projectData.name}--${
                      assignData.activity.activity
                    }--${subAssignData.profile.full_name}`"
                    :isRowHidden="
                      expensesHiddenTasks.includes(task.id) &&
                      isHiddenRowsVisible &&
                      !readonly
                    "
                    :key="`${customerData.name}--${projectData.name}--${assignData.activity.activity}--${subAssignData.profile.full_name}--${task.id}`"
                  >
                    <ExpensesCell
                      v-show="
                        !isCurrentIntervalEqualWeek ||
                        isTaskHasTimeEntries(task)
                      "
                      :numberOfColumn="0"
                      :style="{ 'padding-left': '26px' }"
                      :is-editable="true"
                      :is-general-cell="true"
                      :data="task.name"
                      :taskId="task.id"
                      :isTimeTrackAllowed="true"
                      :timeEntriesList="task.time_entries"
                      @openDeleteModal="openDeleteModal"
                      :dataOfRestoredTask="
                        getDataOfRestoredTask(
                          customerData.name,
                          projectData.name,
                          assignData.activity.activity,
                          task.name
                        )
                      "
                    />
                    <ExpensesCell
                      v-show="
                        !isCurrentIntervalEqualWeek ||
                        isTaskHasTimeEntries(task)
                      "
                      :numberOfColumn="1"
                      :data="getSumOfTaskHours(task)"
                      style="justify-content: center"
                    />
                    <template v-for="numberOfCell in intervalData.amount">
                      <ExpensesCell
                        v-if="
                          !isCurrentIntervalEqualWeek ||
                          isTaskHasTimeEntries(task)
                        "
                        :part="projectData.part"
                        :key="
                          'cell-number-' +
                          task.id +
                          '_' +
                          numberOfCell +
                          '_' +
                          getSelectedInterval.amount
                        "
                        :is-editable="true"
                        :is-general-cell="false"
                        :data="
                          getActualHours(
                            task.time_entries,
                            getDayNumber(numberOfCell - 1)
                          )
                        "
                        :currentTimeEntriesItemId="
                          getCurrentTimeEntriesId(
                            task.time_entries,
                            getDayNumber(numberOfCell - 1)
                          )
                        "
                        :taskId="task.id"
                        :pcDayId="getPCDayId(getDayNumber(numberOfCell - 1))"
                        :numberOfColumn="numberOfCell + 1"
                        :isItWeekendDay="isItWeekendDay(numberOfCell)"
                        :isTrackedTooMuch="
                          !!getCurrentTooMuchTrackedDays.length &&
                          !!getCurrentTooMuchTrackedDays.find(
                            (trackedDay) =>
                              trackedDay.dayId ===
                              getPCDayId(getDayNumber(numberOfCell - 1))
                          )
                        "
                        :isItLastCell="numberOfCell === intervalData.amount"
                        :isTimeTrackAllowed="isTimeTrackAllowed(numberOfCell)"
                        :readonly="readonly"
                      />
                    </template>
                  </ExpensesRow>
                </template>
                <AddNewTaskRow
                  v-if="!readonly"
                  :key="'sub-assign-' + subAssignData.id"
                  :parentSpoilerName="`${customerData.name}--${projectData.name}--${assignData.activity.activity}`"
                >
                  <ExpensesCell
                    :style="{
                      'padding-left': '26px',
                      'background-color': 'rgb(243, 243, 243)',
                    }"
                    :isAddCell="true"
                    :onAddNewTaskClick="addNewTask"
                    :taskInfo="{
                      customerId: customerData.id,
                      projectId: projectData.id,
                      assignId: assignData.id,
                      subAssignId: subAssignData.id,
                    }"
                    :data="'+ добавить новую задачу'"
                    :numberOfColumn="0"
                  />
                  <ExpensesCell
                    :is-editable="false"
                    :is-general-cell="true"
                    :numberOfColumn="1"
                    :isItWeekendDay="true"
                  />
                  <ExpensesCell
                    v-for="newTaskNumber in intervalData.amount"
                    :key="'task-number-' + newTaskNumber"
                    :numberOfColumn="newTaskNumber + 1"
                    :isItWeekendDay="true"
                    :is-editable="false"
                    :is-general-cell="false"
                    :isItLastCell="newTaskNumber === intervalData.amount"
                  />
                </AddNewTaskRow>
              </template>
            </template>
          </template>
        </template>
      </template>

      <ExpensesControl />
      <ExpensesColumnResizer />
    </div>
    <TooltipCloud />

    <DeleteModal ref="deleteModal" @confirmDeleteTask="confirmDeleteTask" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import ExpensesCell from "./ExpensesCell";
import ExpensesRow from "./ExpensesRow";
import StickyHeader from "@/components/StickyHeader.vue";
import Loading from "@/components/Loading";
import AddNewTaskRow from "@/components/expenses/AddNewTaskRow";
import SpoilerRow from "@/components/expenses/SpoilerRow";
import TooltipCloud from "@/components/expenses/TooltipCloud";
import ExpensesControl from "@/components/expenses/ExpensesControl";
import ExpensesColumnResizer from "@/components/expenses/ExpensesColumnResizer";
import { getLocalStorage } from "@/assets/utils";
import ExpensesHeadCell from "@/components/expenses/ExpensesHeadCell";
import DeleteModal from "@/components/modal/DeleteModal";

dayjs.extend(isoWeek);

export default {
  name: "expenses-table",
  components: {
    ExpensesHeadCell,
    TooltipCloud,
    SpoilerRow,
    AddNewTaskRow,
    Loading,
    ExpensesCell,
    StickyHeader,
    ExpensesRow,
    ExpensesControl,
    ExpensesColumnResizer,
    DeleteModal,
  },
  props: {
    expensesList: {
      type: Array,
      default() {
        return [];
      },
    },
    intervalData: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default: () => true,
    },
    profilesModeOn: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      trackedDays: [],
      focusedPosition: {
        top: null,
        left: null,
      },
      removedTaskId: null,
    };
  },
  updated() {
    this.setReadonly(this.readonly);
    let closedSpoilerIds = [];
    if (!this.readonly) {
      closedSpoilerIds =
        JSON.parse(getLocalStorage("CLOSED_SPOILER_IDS")) || [];
    }

    this.setClosedRows(closedSpoilerIds);
  },
  watch: {
    viewedMonthOfYear(dateId) {
      this.setViewedMonthOfYear(dateId);
    },
    isEditableInterval(isTrue) {
      this.setEditableIntervalFlag(isTrue);
    },
    isLoading(isTrue) {
      if (isTrue) {
        this.resetCellRef();
      }
    },
  },
  computed: {
    ...mapState({
      currentDaysCalendar: (state) =>
        state.productionCalendarStore.currentDaysCalendar,
      focusedColumnNumber: (state) => state.expensesStore.focusedColumnNumber,
      expensesHiddenTasks: (state) => state.expensesStore.expensesHiddenTasks,
      isHiddenRowsVisible: (state) => state.expensesStore.isHiddenRowsVisible,
      isTimerActive: (state) => state.expensesStore.isTimerActive,
      timer: (state) => state.expensesStore.timer,
      firstColumnWidth: (state) => state.expensesStore.firstColumnWidth,
      userData: (state) => state.userStore.me,
      currentMonthExpensesList: (state) =>
        state.expensesStore.currentMonthExpensesList,
    }),
    ...mapGetters({
      getCurrentTooMuchTrackedDays:
        "expensesStore/getCurrentTooMuchTrackedDays",
      getCurrentTrackedDays: "expensesStore/getCurrentTrackedDays",
      getMyDate: "userStore/getMyDate",
      getSelectedInterval: "expensesStore/getSelectedInterval",
    }),
    viewedMonthOfYear() {
      return this.intervalData.since.slice(0, -3);
    },
    getAllHoursRatio() {
      let hoursActual = 0;
      let hoursPlan = 0;
      this.expensesList.forEach((expensesItem) => {
        hoursActual += expensesItem.sum_actual_hours;
        hoursPlan += expensesItem.sum_plan_hours;
      });
      return `${hoursActual ? hoursActual.toFixed(1) : 0} / ${hoursPlan.toFixed(
        1
      )}`;
    },
    isCurrentIntervalEqualWeek() {
      return this.intervalData.amount === 7;
    },
    isEditableInterval() {
      const sinceDate = dayjs(this.intervalData.since);
      const untilDate = dayjs(this.intervalData.until)
        .add(1, "day")
        .endOf("day");
      const currentDate = dayjs();
      return sinceDate <= currentDate && currentDate <= untilDate;
    },
  },
  async mounted() {
    const storedTimerData = getLocalStorage("EXPENSES_TIMER_DATA");
    if (storedTimerData) {
      const timerData = JSON.parse(getLocalStorage("EXPENSES_TIMER_DATA"));

      if (!this.readonly && timerData.userId === this.userData.user_id) {
        const timerStartTime = timerData.startTime;
        const timerEndTime = dayjs();
        const timerDuration = timerEndTime.diff(timerStartTime, "second");

        this.startTimer({ ...timerData, timerDuration });
      }
    }
  },
  destroyed() {
    clearInterval(this.timer.timerIntervalId);
    this.setTimerIntervalId(null);
  },
  methods: {
    ...mapActions({
      createNewTask: "expensesStore/createNewTask",
      findProject: "projectsStore/findProject",
      startTimer: "expensesStore/startTimer",
      deleteTask: "expensesStore/deleteTask",
    }),
    ...mapMutations({
      setFocusedColumnNumber: "expensesStore/setFocusedColumnNumber",
      setFocusCreatedInput: "expensesStore/setFocusCreatedInput",
      setReadonly: "expensesStore/setReadonly",
      setClosedRows: "expensesStore/setClosedRows",
      setIsTimerActive: "expensesStore/setIsTimerActive",
      setTimerIntervalId: "expensesStore/setTimerIntervalId",
      setEditableIntervalFlag: "expensesStore/setEditableIntervalFlag",
      setViewedMonthOfYear: "expensesStore/setViewedMonthOfYear",
      resetCellRef: "expensesStore/resetCellRef",
    }),
    async addNewTask(newActivityName, taskInfo) {
      const { customerId, projectId, assignId, subAssignId } = taskInfo;
      const newExpensesList = [...this.expensesList];
      const currentCustomer = newExpensesList.find(
        (customer) => customer.id === customerId
      );
      const currentProject = currentCustomer.projects.find(
        (project) => project.id === projectId
      );

      const currentAssignment = currentProject.assignments.find(
        (assignment) => {
          return assignment.id === assignId;
        }
      );

      const currentSubAssignment = currentAssignment.sub_assignments.find(
        (subAssignment) => subAssignment.id === subAssignId
      );
      const result = await this.createNewTask({
        name: newActivityName,
        sub_assignment: currentSubAssignment.id,
      });
      if (
        !result.isSuccess &&
        result.details.non_field_errors?.includes(
          "Cannot create, update or delete object in this period"
        )
      ) {
        this.$pushMessage({
          message: "Действие невозможно/действие запрещено",
          title: "Создание задачи в недопустимый период",
        });
      }

      if (result.isSuccess) {
        this.setFocusCreatedInput({ timeEntriesId: result.response.id });
      }
    },
    getDayNumber(n) {
      return dayjs(this.intervalData.since).add(n, "day").date();
    },
    isItWeekendDay(n) {
      const currentDayNumber = this.getDayNumber(n - 1);
      const dayType = this.currentDaysCalendar.find(
        (day) => day.number === currentDayNumber
      )?.day_type;
      return dayType === "DAY_OFF";
    },
    getActualHours(timeEntries, dayNumber) {
      const currentTimeEntriesItem = timeEntries.find(
        (timeEntriesItem) => timeEntriesItem.pc_day.number === dayNumber
      );
      return currentTimeEntriesItem
        ? currentTimeEntriesItem.actual_hours?.toString()
        : "";
    },
    getPCDayId(dayNumber) {
      return this.currentDaysCalendar.find((day) => day.number === dayNumber)
        ?.id;
    },
    getCurrentTimeEntriesId(timeEntries, dayNumber) {
      const currentTimeEntriesItem = timeEntries.find(
        (timeEntriesItem) => timeEntriesItem.pc_day.number === dayNumber
      );
      return currentTimeEntriesItem ? currentTimeEntriesItem.id : 0;
    },
    getSumOfTaskHours(task) {
      return task.time_entries
        .reduce((acc, timeEntriesItem) => {
          return acc + timeEntriesItem.actual_hours;
        }, 0)
        .toFixed(1)
        .toString();
    },
    isTaskHasTimeEntries(task) {
      console.warn("is", task, task.time_entries.length);
      return !!task.time_entries.length;
    },
    isTimeTrackAllowed(numberOfCell) {
      const currentSinceIntervalMonth =
        +this.intervalData.since.split("-")[1] - 1;
      return (
        (this.getMyDate.month() === currentSinceIntervalMonth &&
          this.getMyDate.date() >= numberOfCell) ||
        (this.getMyDate.date() === 1 &&
          this.getMyDate.month() === currentSinceIntervalMonth + 1)
      );
    },
    getTrackedHours(dayNumber, profileId = 0) {
      let trackedHours = "";

      const currentTrackedDay =
        !!this.getCurrentTrackedDays.length &&
        this.getCurrentTrackedDays.find(
          (trackedDay) =>
            trackedDay.dayId ===
            this.getPCDayId(this.getDayNumber(dayNumber - 1))
        );
      if (currentTrackedDay && currentTrackedDay.actual_hours) {
        if (!profileId) {
          trackedHours = `${currentTrackedDay.actual_hours.toFixed(1)}`;
        } else {
          const currentProfileTrackedDay = currentTrackedDay.profileIds.find(
            (trackedProfile) => trackedProfile.profile === profileId
          );
          if (currentProfileTrackedDay) {
            trackedHours = `${currentProfileTrackedDay.actual_hours.toFixed(
              1
            )}`;
          }
        }
      }

      return trackedHours;
    },
    isTaskVisible(taskId) {
      return !this.readonly
        ? !this.expensesHiddenTasks.includes(taskId) ||
            (this.expensesHiddenTasks.includes(taskId) &&
              this.isHiddenRowsVisible)
        : true;
    },
    openDeleteModal(taskId) {
      this.removedTaskId = taskId;
      this.$refs.deleteModal.openModal();
    },
    async confirmDeleteTask() {
      await this.deleteTask({ id: this.removedTaskId });
      this.removedTaskId = null;
    },
    getDataOfRestoredTask(customerName, projectName, assignmentName, taskName) {
      // если readonly -> true, то это таблица просмотра трудозатрат и иконка должна быть скрыта
      if (this.readonly) {
        return {};
      }
      // Ищем клиента в списке клиентов текущего месяца
      const customer = this.currentMonthExpensesList.find(
        (customer) => customer.name.trim() === customerName.trim()
      );

      if (!customer) {
        return {}; // Клиент не найден, задачи - нет
      }

      // Ищем проект в списке проектов клиента
      const project = customer.projects.find(
        ({ name }) => name.trim() === projectName.trim()
      );

      if (!project) {
        return {}; // Проект не найден, задачи - нет
      }

      // Ищем направление деятельности в списке текущего проекта
      const assignment = project.assignments.find(
        (assignment) =>
          assignment.activity.activity.trim() === assignmentName.trim()
      );

      if (!assignment) {
        return {}; // Направление не найдено, задачи - нет
      }

      // Проверяем наличие задачи среди задач направления
      // Если есть такая задача в текущем месяце, то иконку отображать не надо
      let taskSubAssignmentId;
      for (const subAssignment of assignment.sub_assignments) {
        taskSubAssignmentId = subAssignment.id;
        for (const task of subAssignment.tasks) {
          if (task.name.trim() === taskName.trim()) {
            return {
              taskSubAssignmentId: null,
              isIconVisible: false,
            }; // Задача найдена, возвращаем false
          }
        }
      }

      return { taskSubAssignmentId, isIconVisible: true }; // Задача не найдена - отобразить иконку
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__wrapper {
  position: relative;
}

.expenses {
  width: min-content;
  margin-right: 50px;
  border: 1px solid var(--primary-bg-color);
  padding: 1px;
  position: relative;
}

.expenses__head {
  background-color: var(--primary-bg-color);
  color: #ffffff;
  text-align: center;
  font-weight: 400;
  display: grid;
}
</style>
