<template>
  <div
    ref="cellRef"
    class="expenses__body-cell"
    @click="changeFocusCellNumber(numberOfColumn)"
    :style="{
      left:
        numberOfColumn === 0
          ? `${zeroColumnLeftIndent}px`
          : numberOfColumn === 1
          ? `${leftIndent}px`
          : '0',
    }"
    :class="{
      'expenses__body-cell--non-editable': isGeneralCell && !isEditable,
      'expenses__body-cell--editable': isEditable,
      'expenses__body-cell--add-cell': isAddCell,
      'expenses__body-cell--today':
        (numberOfColumn !== 0) & (numberOfColumn !== 1) &&
        (getIsItDayToday ||
          (isSelectedIntervalIncludeCurrentDay &&
            getDayNumber - 1 === getMyDate.date())),
      'expenses__body-cell--monday': getIsItMonday,
      'expenses__body-cell--workday': !isItWeekendDay,
      'expenses__body-cell--weekend-day': isItWeekendDay,
      'expenses__body-cell--last-cell': isItLastCell,
      'expenses__body-cell--sticky':
        numberOfColumn === 0 || numberOfColumn === 1,
      'expenses__body-cell--head': isHeadCell,
    }"
  >
    <template v-if="isAddCell && addCellModeOn">
      <form
        @submit.prevent="addNewCell"
        class="expenses__body-cell-data expenses__body-cell-data--form"
      >
        <input
          v-focus
          v-model.trim="addCellDataInput"
          placeholder="введите название"
          class="expenses__body-cell-data expenses__body-cell-data-add--input"
          type="text"
        />
        <div class="expenses__body-cell-data expenses__body-cell-data--buttons">
          <input
            class="expenses__body-cell-data expenses__body-cell-data--buttons-item"
            type="button"
            value="отмена"
            @click="
              () => {
                this.addCellModeOn = false;
                this.addCellDataInput = '';
              }
            "
          />
          <input
            class="expenses__body-cell-data expenses__body-cell-data--buttons-item"
            type="button"
            value="ok"
            @click="addNewCell"
          />
        </div>
      </form>
    </template>
    <template v-else>
      <template v-if="isEditable && !isGeneralCell">
        <Input
          v-if="isTimeTrackAllowed"
          ref="inputRef"
          :startValue="data"
          :is-only-numbers="true"
          :classNames="{
            'expenses__body-cell-data': true,
            'expenses__body-cell-data--input': true,
            'expenses__body-cell-data--weekend-day': isItWeekendDay,
            'expenses__body-cell-data--too-much-tracked':
              isTrackedTooMuch && isTimeTrackAllowed,
          }"
          :save-data="saveDataInput"
          :disabled="this.getReadonly"
          @number-out-range="showRangeError"
          :timeFormat="expensesTimeFormat"
        />
        <div v-else class="expenses__body-cell-data--not-allowed">
          <Hatch class="expenses__body-cell-data--not-allowed-hatch" />
          {{ data }}
        </div>
      </template>
      <template v-else>
        <div
          v-if="
            isGeneralCell &&
            isEditable &&
            isOpen === null &&
            !this.changeTaskModeOn &&
            isTimeTrackAllowed &&
            isSelectedIntervalIncludeCurrentDay &&
            !this.getReadonly
          "
          :class="{
            'expenses__body-cell-data': true,
            'expenses__body-cell-timer-icon': true,
            'expenses__body-cell-timer-icon--visible':
              isTimerActive && timer.taskId === taskId,
          }"
        >
          <template v-if="!isTimerActive">
            <Button theme="hoverIcon" @click="timerStartHandler">
              <StartIcon />
            </Button>
          </template>
          <template v-if="isTimerActive && timer.taskId === taskId">
            <Button theme="icon" @click="timerStopHandler">
              <StopIcon />
            </Button>
          </template>
        </div>
        <div
          v-if="
            !isSelectedIntervalIncludeCurrentDay &&
            isGeneralCell &&
            dataOfRestoredTask.isIconVisible
          "
          class="expenses__body-cell-data expenses__body-cell-restore-icon"
        >
          <Button theme="hoverIcon" @click="onTaskRestoreClick">
            <Tooltip
              :tooltipData="'Перенести задачу в текущий месяц'"
              :withIcon="true"
              :customIcon="ClockIcon"
            />
          </Button>
        </div>
        <div
          v-if="!this.changeTaskModeOn"
          class="expenses__body-cell-data expenses__body-cell-data--name"
          :class="{
            'expenses__body-cell-data--disabled':
              !this.isCurrentIntervalHaveAccess,
            'expenses__body-cell-data--bold-weight':
              isFontWeightBold && !getReadonly,
          }"
          @click="cellClick"
        >
          <span class="expenses__body-cell-spoiler-icon" v-if="isOpen !== null">
            <SpoilerIcon
              :styleObject="{
                transform: isOpen ? 'rotate(0)' : 'rotate(-90deg)',
              }"
            />
          </span>
          <Tooltip
            v-if="isGeneralCell && !!data"
            :tooltipData="data"
            :tooltip-background="isEditable ? '#fff' : '#ccc'"
            :classNames="{ 'expenses__body-cell-data--text': true }"
            :editHandler="
              isEditable && !this.getReadonly ? openChangeTaskMode : null
            "
            :size-update-time="sizeUpdateTime"
          />
          <p v-else class="expenses__body-cell-data--text">{{ data }}</p>
          <div
            v-if="projectDescription"
            class="expenses__body-cell-data--description"
          >
            <Tooltip
              :tooltipData="projectDescription"
              :tooltip-background="'#fff'"
              :withIcon="true"
            />
          </div>
        </div>
        <form
          v-else
          class="expenses__body-cell-data expenses__body-cell-data--form"
          @submit.prevent="changeTask"
        >
          <input
            ref="taskDataInputRef"
            autofocus
            v-model.trim="changeTaskDataInput"
            placeholder="введите название"
            class="expenses__body-cell-data expenses__body-cell-data-add--input"
            type="text"
          />
          <div
            class="expenses__body-cell-data expenses__body-cell-data--buttons"
          >
            <input
              class="expenses__body-cell-data expenses__body-cell-data--buttons-item"
              type="button"
              value="отмена"
              @click="
                () => {
                  this.changeTaskModeOn = false;
                  this.changeTaskDataInput = this.defaultTaskDataInput;
                }
              "
            />
            <input
              class="expenses__body-cell-data expenses__body-cell-data--buttons-item"
              type="button"
              value="ok"
              @click.prevent="changeTask"
            />
          </div>
        </form>
        <div
          v-if="hoursRatio"
          class="expenses__body-cell-data expenses__body-cell-data--hours"
        >
          {{ hoursRatio }}
        </div>
        <div
          v-if="
            isGeneralCell &&
            isEditable &&
            isOpen === null &&
            !this.changeTaskModeOn &&
            isTimeTrackAllowed
          "
          class="expenses__body-cell-data expenses__icons"
        >
          <template v-if="!this.getReadonly">
            <div class="expenses__icons-eye-wrapper">
              <Button theme="hoverIcon" @click="checkRowVisibility(taskId)">
                <ShowIcon />
              </Button>
            </div>
            <div class="expenses__icons-trash-wrapper">
              <Button
                theme="hoverIcon"
                @click="!isCurrentTaskActive && clickDeleteTask()"
                :disabled="!isCurrentIntervalHaveAccess || isCurrentTaskActive"
              >
                <Trash />
              </Button>
            </div>
          </template>
        </div>
      </template>
    </template>
    <div v-if="numberOfColumn === 1" class="expenses__body-cell--sticky-border">
      <div
        class="expenses__body-cell-resizer"
        draggable="true"
        @dragstart="onResizeStart"
        @drag="resizeColumn"
        @dragend="resizeEnd"
      ></div>
    </div>
  </div>
</template>

<script>
import Input from "../Input";
import { mapGetters, mapActions, mapMutations, mapState } from "vuex";
import Trash from "@/assets/img/trash.svg";
import ShowIcon from "@/assets/img/eye-icon.svg";
import StartIcon from "@/assets/img/play-icon.svg";
import ClockIcon from "@/assets/img/clock-icon.svg";
import StopIcon from "@/assets/img/stop-icon.svg";
import Hatch from "@/assets/img/hatch.svg";
import Tooltip from "./TooltipBody";
import SpoilerIcon from "@/components/icons/SpoilerIcon";
import dayjs from "dayjs";
import { convertMinutesToHours, getLocalStorage } from "../../assets/utils";
import Button from "@/components/Button";

export default {
  name: "ExpensesCell",
  data() {
    return {
      zeroColumnLeftIndent: 180,
      addCellModeOn: false,
      addCellDataInput: "",
      changeTaskModeOn: false,
      defaultTaskDataInput: this.data,
      changeTaskDataInput: this.data,
      tableLeft: 0,
      ClockIcon,
    };
  },
  components: {
    Tooltip,
    Input,
    SpoilerIcon,
    Trash,
    StartIcon,
    StopIcon,
    Hatch,
    ShowIcon,
    Button,
  },
  props: {
    data: {
      type: String,
    },
    hoursRatio: {
      type: String,
    },
    isOpen: {
      type: Boolean,
      default() {
        return null;
      },
    },
    onSpoilerClick: {
      type: Function,
    },
    isEditable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isGeneralCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isAddCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    onAddNewTaskClick: {
      type: Function,
    },
    taskInfo: {
      type: Object,
    },
    taskId: {
      type: Number,
    },
    pcDayId: {
      type: Number,
    },
    currentTimeEntriesItemId: {
      type: Number,
    },
    numberOfColumn: {
      type: Number,
      default() {
        return -2;
      },
    },
    isItWeekendDay: {
      type: Boolean,
    },
    isTrackedTooMuch: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isItLastCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isTimeTrackAllowed: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isSigmaCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isHeadCell: {
      type: Boolean,
      default() {
        return false;
      },
    },
    projectDescription: {
      type: String,
    },
    isFontWeightBold: {
      type: Boolean,
      default() {
        return false;
      },
    },
    timeEntriesList: {
      type: Array,
      default() {
        return [];
      },
    },
    dataOfRestoredTask: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  created() {
    if (this.getIsItDayToday && !this.timerPcDayId) {
      this.setTimerPcDayId(this.pcDayId);
    }
  },
  beforeDestroy() {
    const currentCellRef = this.$refs.cellRef;
    const focusedCellPosition = this.getFocusedCellPosition;
    const focusedCellRef = focusedCellPosition.cellRef;
    if (focusedCellRef === currentCellRef) {
      this.setFocusedCellPosition({
        ...focusedCellPosition,
        isVisionOn: false,
      });
    }
  },
  mounted() {
    const { taskId, numberOfColumn } = this.getFocusedCellPosition;

    if (
      taskId &&
      taskId === this.taskId &&
      numberOfColumn === this.numberOfColumn
    ) {
      this.changeFocusCellNumber(this.numberOfColumn);
    }

    const table = document.getElementById("expenses-table");
    if (table) {
      this.tableLeft = table.getBoundingClientRect().left;
    }
  },
  methods: {
    ...mapActions({
      createNewTimeEntries: "expensesStore/createNewTimeEntries",
      patchTimeEntries: "expensesStore/patchTimeEntries",
      deleteTimeEntries: "expensesStore/deleteTimeEntries",
      patchTask: "expensesStore/patchTask",
      startTimer: "expensesStore/startTimer",
      stopTimer: "expensesStore/stopTimer",
      createNewTask: "expensesStore/createNewTask",
      loadExpensesList: "expensesStore/loadExpensesList",
    }),
    ...mapMutations({
      setFocusedCellPosition: "expensesStore/setFocusedCellPosition",
      setExpensesHiddenTasks: "expensesStore/setExpensesHiddenTasks",
      setTimer: "expensesStore/setTimer",
      setIsTimerActive: "expensesStore/setIsTimerActive",
      setTimerPcDayId: "expensesStore/setTimerPcDayId",
      setTimerPrevDuration: "expensesStore/setTimerPrevDuration",
      setTimerTimeEntryId: "expensesStore/setTimerTimeEntryId",
      setResizeX: "expensesStore/setResizeX",
      resizeStart: "expensesStore/resizeStart",
      resizeEnd: "expensesStore/resizeEnd",
    }),
    showRangeError(range) {
      this.$pushMessage({
        message:
          `Максимальное допустимое значение: ${range.max}ч/${range.maxMinutes}мин\n` +
          `Минимально допустимое значение: ${range.min}\n\n` +
          `При треке времени в часах разделение десятичных долей допустимо с помощью точки или запятой\n` +
          `При треке времени в минутах в ячейку можно вносить только целые числа\n\n` +
          `Возможно, вы не можете внести значение потому, что заполняете трудозатраты не в тех единицах времени`,
        title: "Указание часов выполнения задачи",
      });
    },
    async cellClick() {
      if (this.isAddCell && this.isCurrentIntervalHaveAccess) {
        this.changeAddCellMode();
      } else if (this.onSpoilerClick) {
        await this.onSpoilerClick();
      }
    },
    async addNewCell() {
      if (this.addCellDataInput) {
        await this.onAddNewTaskClick(this.addCellDataInput, this.taskInfo);
        this.addCellModeOn = false;
        this.addCellDataInput = "";
      } else {
        alert("сперва, введите название для новой задачи");
      }
    },
    openChangeTaskMode() {
      if (this.isCurrentIntervalHaveAccess) {
        this.changeTaskModeOn = true;
        this.changeTaskDataInput = this.data;
      }
    },
    async changeTask() {
      const newTaskName = this.changeTaskDataInput;
      if (newTaskName.length && newTaskName !== this.data) {
        const taskId = this.taskId;
        const result = await this.patchTask({ name: newTaskName, id: taskId });
        if (result?.isSuccess) {
          this.defaultTaskDataInput = newTaskName;
          if (taskId === this.timer.taskId) {
            this.setTimer({
              ...this.timer,
              taskName: newTaskName,
            });
          }
        }
      }
      this.changeTaskModeOn = false;
    },
    async clickDeleteTask() {
      if (this.isCurrentIntervalHaveAccess) {
        this.$emit("openDeleteModal", this.taskId);
      }
    },
    async saveDataInput(actual_hours, isTriggeredByTimer = false) {
      const taskId =
        this.$props.taskId || !isTriggeredByTimer
          ? this.$props.taskId
          : this.timer.taskId;
      const pcDayId =
        this.$props.pcDayId || !isTriggeredByTimer
          ? this.$props.pcDayId
          : this.timerPcDayId;
      const currentTimeEntriesItemId =
        this.$props.currentTimeEntriesItemId || !isTriggeredByTimer
          ? this.$props.currentTimeEntriesItemId
          : this.timer.timeEntryId;

      if (this.isEditable || isTriggeredByTimer) {
        let result = null;
        if (currentTimeEntriesItemId) {
          if (actual_hours) {
            result = await this.patchTimeEntries({
              actual_hours,
              id: currentTimeEntriesItemId,
            });
            if (pcDayId === this.timerPcDayId) {
              this.setTimerPrevDuration(actual_hours);
            }
          } else {
            result = await this.deleteTimeEntries({
              id: currentTimeEntriesItemId,
            });
            if (pcDayId === this.timerPcDayId) {
              this.setTimerPrevDuration(0);
              this.setTimerTimeEntryId(0);
            }
          }
        } else {
          if (actual_hours) {
            result = await this.createNewTimeEntries({
              actual_hours,
              pc_day: pcDayId,
              taskId,
              dayNumber: this.getDayNumber,
              isCurrentMonthActive:
                this.isSelectedIntervalIncludeCurrentDay ||
                this.isSelectedIntervalIncludeOnlyOnFirstDayPrevMonth,
            });
            if (pcDayId === this.timerPcDayId) {
              this.setTimerPrevDuration(actual_hours);
              this.setTimerTimeEntryId(result.id);
            }
          }
        }
        return result;
      }
    },
    changeAddCellMode() {
      this.addCellModeOn = !this.addCellModeOn;
    },
    changeFocusCellNumber(numberOfColumn) {
      if (
        !this.isSelectedIntervalIncludeCurrentDay &&
        !this.isSelectedIntervalIncludeOnlyOnFirstDayPrevMonth
      ) {
        return;
      }

      if (!this.taskId) {
        return;
      }

      if (!this.getReadonly) {
        const cellRef = this.$refs.cellRef;

        this.setFocusedCellPosition({
          cellRef,
          numberOfColumn: numberOfColumn,
          isVisionOn: true,
          taskId: this.taskId,
        });
      }
    },
    checkRowVisibility(taskId) {
      let tasksFromStorage =
        JSON.parse(getLocalStorage("EXPENSES_HIDDEN_TASKS")) || [];
      if (tasksFromStorage.includes(taskId)) {
        tasksFromStorage = tasksFromStorage.filter((id) => id !== taskId);
      } else {
        tasksFromStorage.push(taskId);
      }

      window.localStorage.setItem(
        "EXPENSES_HIDDEN_TASKS",
        JSON.stringify(tasksFromStorage)
      );
      this.setExpensesHiddenTasks(tasksFromStorage);
    },
    timerStartHandler() {
      const taskCurrentDayTimeEntry = this.timeEntriesList.find(
        ({ pc_day }) => pc_day.id === this.timerPcDayId
      );
      this.startTimer({
        taskId: this.taskId,
        taskName: this.data,
        currentDay: this.getMyDate.date(),
        timeEntryId: taskCurrentDayTimeEntry?.id || 0,
        prevDuration: taskCurrentDayTimeEntry?.actual_hours || 0,
        startTime: dayjs(),
        userId: this.user?.user_id,
      });
    },
    timerStopHandler() {
      this.setIsTimerActive(false);
    },
    async onTaskRestoreClick() {
      const result = await this.createNewTask({
        name: this.data,
        sub_assignment: this.dataOfRestoredTask.taskSubAssignmentId,
      });
      if (result.isSuccess) {
        this.$pushMessage({
          title: "Задача перенесена",
          message: `Задача "${this.data}" была успешно перенесена в текущий месяц`,
        });
        await this.loadExpensesList({
          ...this.currentInterval,
          isOnlyCurrent: true,
        });
      }
    },
    onResizeStart(event) {
      this.setResizeX(event.clientX);
      this.resizeStart();
    },
    resizeColumn(event) {
      const SUM_COLUMN_WIDTH = 50;
      const width = event.clientX - this.tableLeft - SUM_COLUMN_WIDTH;
      this.setResizeX(width);
    },
  },
  directives: {
    focus: {
      inserted: function (el) {
        el.focus();
      },
    },
  },
  computed: {
    ...mapState({
      timer: (state) => state.expensesStore.timer,
      isTimerActive: (state) => state.expensesStore.isTimerActive,
      timerDuration: (state) => state.expensesStore.timerDuration,
      timerPcDayId: (state) => state.expensesStore.timerPcDayId,
      user: (state) => state.userStore.me,
      expensesTimeFormat: (state) => state.expensesStore.expensesTimeFormat,
      currentInterval: (state) => state.expensesStore.currentInterval,
      sizeUpdateTime: (state) => state.expensesStore.sizeUpdateTime,
      firstColumnWidth: (state) => state.expensesStore.firstColumnWidth,
    }),
    ...mapGetters({
      getReadonly: "expensesStore/getReadonly",
      getFocusCreatedInput: "expensesStore/getFocusCreatedInput",
      getFocusedCellPosition: "expensesStore/getFocusedCellPosition",
      getSelectedInterval: "expensesStore/getSelectedInterval",
      getMyDate: "userStore/getMyDate",
    }),
    leftIndent() {
      return this.firstColumnWidth + this.zeroColumnLeftIndent;
    },
    getDayNumber() {
      return dayjs(this.getSelectedInterval.since)
        .add(this.numberOfColumn - 2, "day")
        .date();
    },
    isSelectedIntervalIncludeCurrentDay() {
      const { since, until } = this.getSelectedInterval;
      const startDate = dayjs(since).startOf("month");
      const endDate = dayjs(until).endOf("month");
      const currentDate = this.getMyDate.toDate();
      return (
        currentDate >= startDate.toDate() && currentDate <= endDate.toDate()
      );
    },
    isSelectedIntervalIncludeOnlyOnFirstDayPrevMonth() {
      const { since } = this.getSelectedInterval;
      const currentDate = this.getMyDate;
      const isIntervalMonthPrevMonth =
        dayjs(since).add(1, "month").month() === currentDate.month();
      const currentDayNumber = currentDate.date();

      return isIntervalMonthPrevMonth && currentDayNumber === 1;
    },
    getIsItDayToday() {
      return (
        this.isSelectedIntervalIncludeCurrentDay &&
        this.getDayNumber === this.getMyDate.date()
      );
    },
    getWeekDay() {
      const days = ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"];
      const dayOfWeek = dayjs(this.getSelectedInterval.since, "YYYY-MM-DD")
        .add(this.numberOfColumn - 1, "day")
        .isoWeekday();
      return days[dayOfWeek - 1];
    },
    getIsItMonday() {
      return this.getWeekDay === "ПН" || this.numberOfColumn === 1;
    },
    isCurrentIntervalHaveAccess() {
      if (!this.getSelectedInterval.amount) {
        return false;
      }
      const currentSinceIntervalMonth =
        +this.getSelectedInterval.since.split("-")[1] - 1;
      return (
        this.getMyDate.month() === currentSinceIntervalMonth ||
        (this.getMyDate.date() === 1 &&
          this.getMyDate.month() === currentSinceIntervalMonth + 1)
      );
    },
    isCurrentTaskActive() {
      return this.timer.taskId === this.taskId;
    },
  },
  watch: {
    getFocusCreatedInput(value) {
      if (this.getIsItDayToday && value.timeEntriesId === this.taskId) {
        const viewportWidth = window?.innerWidth;
        const halfViewAriaWidth = (viewportWidth - this.firstColumnWidth) / 2;
        const cellCoordLeft =
          this.$refs.inputRef?.$el.getBoundingClientRect().left;
        const scrollAmount =
          cellCoordLeft - (this.firstColumnWidth + halfViewAriaWidth);
        setTimeout(() => {
          scrollBy({
            top: 0,
            left: scrollAmount,
            behavior: "smooth",
          });
          this.changeFocusCellNumber(this.numberOfColumn);
          this.$refs.inputRef?.$el.focus({ preventScroll: true });
        }, 100);
      }
    },
    changeTaskModeOn(value) {
      if (value) {
        setTimeout(() => {
          this.$refs.taskDataInputRef?.focus();
        }, 100);
      }
    },
    isTimerActive(value) {
      if (
        !value &&
        !!this.timer.timerDuration &&
        this.timer.currentDay === this.getDayNumber &&
        (this.timer.taskId === this.taskId || this.timer.timeEntryId >= 0)
      ) {
        const timerStartTime = this.timer.startTime;
        const timerEndTime = dayjs();
        const trackedHours = convertMinutesToHours(
          timerEndTime.diff(timerStartTime, "second") / 60
        );
        const totalHours = (
          parseFloat(trackedHours) + parseFloat(this.timer.prevDuration)
        ).toFixed(1);

        this.saveDataInput(parseFloat(totalHours), true);
        this.stopTimer();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.expenses__body-cell {
  justify-content: space-between;
  display: flex;
  padding: 2px 5px;
  position: relative;
  z-index: 8;
  border-top: 1px solid #ffffff;

  &:hover {
    .expenses__body-cell-timer-icon {
      visibility: visible;
    }
  }
}

.expenses__body-cell--editable {
  justify-content: space-between;

  .expenses__body-cell-data--name {
    width: calc(100% - 55px);

    .expenses__body-cell-data--text {
      white-space: pre;
      overflow: hidden;
      width: 100%;
    }
  }
}

.expenses__body-cell-data--description {
  margin-left: 3px;
  line-height: 0;
}

.expenses__body-cell--non-editable:not(.expenses__body-cell--add-cell) {
  .expenses__body-cell-data--name {
    height: 20px;
    width: 50px;
    flex-basis: 100%;

    .expenses__body-cell-data--text {
      overflow: hidden;
    }
  }

  .expenses__body-cell-data--bold-weight {
    font-weight: 700;
  }

  .expenses__body-cell-data--hours {
    white-space: pre;
  }
}

.expenses__body-cell--non-editable,
.expenses__body-cell--add-cell {
  .expenses__body-cell-data--name {
    cursor: pointer;
  }
}

.expenses__body-cell--add-cell {
  .expenses__body-cell-data--disabled {
    cursor: auto;
    opacity: 0.3;
  }
}

.expenses__body-cell--non-editable:not(.expenses__body-cell--add-cell) {
  background-color: var(--non-editable-bg-color);
}

.expenses__body-cell {
  &.expenses__body-cell--last-cell {
    border-right: 1px solid #ffffff;
  }

  &.expenses__body-cell--workday:before {
    width: 1px;
    height: calc(100% + 2px);
    background-color: #efefef;
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    display: block;
  }

  &.expenses__body-cell--weekend-day {
    background-color: #f3f3f3;
  }

  &.expenses__body-cell--monday:before,
  &.expenses__body-cell--today:before {
    width: 1px;
    height: calc(100% + 2px);
    position: absolute;
    top: -1px;
    content: "";
    display: block;
  }

  &.expenses__body-cell--monday:before {
    background-color: #999999;
    width: 1px;
    left: -1px;
  }

  &.expenses__body-cell--today:before {
    background-color: #1981c9;
    left: 1px;
    z-index: 1;
  }
}

.expenses__body-cell-data--input {
  padding: 0;
  border: none;
  max-width: 100%;
  text-align: center;
  position: absolute;
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  top: 1px;
  left: 1px;
}

.expenses__body-cell-data--name {
  display: flex;
  align-items: center;

  .expenses__body-cell-spoiler-icon {
    transform: rotate(0);
    margin-right: 2px;
    transition: all 2s ease-in-out;
    line-height: 0;
  }
}

.expenses__icons {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.expenses__icons-eye-wrapper,
.expenses__icons-trash-wrapper {
  height: 17px;
}

.expenses__icons-trash-wrapper {
  width: 15px;
}

.expenses__body-cell .expenses__body-cell-data--form {
  height: 17px;
  margin-left: 0;
  display: flex;
  width: 100%;

  .expenses__body-cell-data-add--input {
    text-align: start;
    border: 1px solid gray;
    border-radius: 4px;
    width: 100%;
    padding-left: 4px;
  }

  .expenses__body-cell-data--buttons {
    display: flex;
    justify-content: space-between;
    margin-left: 8px;

    .expenses__body-cell-data--buttons-item {
      cursor: pointer;
      padding: 0 4px;
      font-size: 14px;
    }

    .expenses__body-cell-data--buttons-item:first-child {
      margin-right: 4px;
    }
  }
}

.expenses__body-cell-data--weekend-day {
  background-color: #f3f3f3;
}

.expenses__body-cell-data--too-much-tracked {
  background-color: #e9736e;
  color: #fff;
}

.expenses__body-cell-data--not-allowed {
  width: 49px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 0;

  .expenses__body-cell-data--not-allowed-hatch {
    position: absolute;
    left: 0;
  }
}

.expenses__body-cell--sticky {
  position: sticky;
  z-index: 9;
  background-color: #fff;
}

.expenses__body-cell--sticky-border:after {
  content: "";
  display: block;
  position: absolute;
  right: -1px;
  top: 0px;
  height: calc(100% + 2px);
  width: 1px;
  background-color: #999999;
}

.expenses__body-cell--head.expenses__body-cell--head:before,
.expenses__body-cell--head.expenses__body-cell--head:after {
  display: none;
}

.expenses__body-cell-timer-icon,
.expenses__body-cell-restore-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  visibility: hidden;
  cursor: default;

  svg {
    width: 100%;
    height: 100%;
  }

  button,
  .tooltip {
    width: 100%;
  }
}

.expenses__body-cell-restore-icon {
  visibility: visible;
}

.expenses__body-cell-timer-icon--visible {
  visibility: visible;
}

.expenses__body-cell-resizer {
  position: absolute;
  right: 0;
  top: -2px;
  height: calc(100% + 4px);
  width: 4px;
  opacity: 0;
  cursor: col-resize;
}
</style>
