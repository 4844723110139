var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"expenses__wrapper"},[(_vm.isLoading)?_c('Loading'):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"expenses",attrs:{"id":"expenses-table"}},[_c('StickyHeader',[_c('div',{ref:"tableHeaderRef",staticClass:"expenses__head",style:({
          'grid-template-columns': `${_vm.firstColumnWidth}px repeat(${
            _vm.getSelectedInterval.amount + 1
          }, 50px)`,
        })},[_c('ExpensesHeadCell',{attrs:{"isItEmptyCell":true,"isTimerCell":_vm.isTimerActive && !_vm.readonly,"numberOfColumn":0}}),_c('ExpensesHeadCell',{attrs:{"isItSumCell":true,"numberOfColumn":1}}),_vm._l((_vm.getSelectedInterval.amount),function(n){return _c('ExpensesHeadCell',{key:'header-day-' + n + 1,attrs:{"numberOfColumn":n + 1}})})],2)]),(!_vm.readonly)?_c('ExpensesRow',{style:({
        'border-left-color': '#263238',
      }),attrs:{"isNonEditable":true}},[_c('ExpensesCell',{staticStyle:{"background-color":"var(--primary-bg-color)","border-left":"none","margin-top":"-1px","height":"calc(100% + 1px)","width":"calc(100% + 1px)","margin-left":"0"},attrs:{"numberOfColumn":0}}),_c('ExpensesCell',{staticStyle:{"justify-content":"center","background-color":"var(--primary-bg-color)","color":"#ffffff","margin-top":"-1px","height":"calc(100% + 1px)","border-left":"none"},attrs:{"numberOfColumn":1,"data":'Σ',"is-head-cell":true}}),_vm._l((_vm.intervalData.amount),function(n){return _c('ExpensesCell',{key:'tracked-hours-' + n,staticStyle:{"justify-content":"center"},attrs:{"numberOfColumn":n + 1,"isItLastCell":n === _vm.intervalData.amount,"data":_vm.getTrackedHours(n)}})})],2):_vm._e(),(!_vm.readonly)?_c('ExpensesRow',{attrs:{"isEditable":false}},[_c('ExpensesCell',{attrs:{"is-general-cell":true,"hoursRatio":_vm.getAllHoursRatio,"numberOfColumn":0}}),_vm._l((_vm.intervalData.amount + 1),function(n){return _c('ExpensesCell',{key:n,attrs:{"numberOfColumn":n,"is-general-cell":true,"isItLastCell":n - 1 === _vm.intervalData.amount}})})],2):_vm._e(),_vm._l((_vm.readonly && _vm.profilesModeOn
        ? _vm.expensesList
        : [{}]),function(profilesData,profilesIndex){return [(_vm.readonly && _vm.profilesModeOn)?_c('SpoilerRow',{key:'pre-profile-' + profilesData.id + `-${profilesIndex}`,attrs:{"rowData":{
          sum_plan_hours: profilesData.customers.reduce(
            (acc, customer) => acc + customer.sum_plan_hours,
            0
          ),
          sum_actual_hours: profilesData.customers.reduce(
            (acc, customer) => acc + customer.sum_actual_hours,
            0
          ),
          name: `${profilesData.last_name} ${profilesData.first_name}`,
        },"spoilerType":'pre-profile',"spoilerName":`${
          _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}` : ''
        }`,"customCellsData":(n) => _vm.getTrackedHours(n, profilesData.id)}}):_vm._e(),_vm._l((_vm.profilesModeOn
          ? profilesData.customers
          : _vm.expensesList),function(customerData){return [_c('SpoilerRow',{key:'customer-' + customerData.id + `-${profilesData.id}`,attrs:{"rowData":customerData,"spoilerType":'customer',"spoilerName":`${
            _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}--` : ''
          }${customerData.name}`}}),_vm._l((customerData.projects),function(projectData){return [_c('SpoilerRow',{key:'project-' + projectData.id + `-${profilesData.id}`,attrs:{"rowData":projectData,"spoilerType":'project',"spoilerName":`${
              _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}--` : ''
            }${customerData.name}--${projectData.name}`}}),_vm._l((projectData.assignments),function(assignData){return [(!!assignData.activity)?_c('SpoilerRow',{key:'activity-' + assignData.id + `-${profilesData.id}`,attrs:{"rowData":{
                ...assignData,
                name: assignData.activity.activity,
              },"spoilerType":'activity',"spoilerName":`${
                _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}--` : ''
              }${customerData.name}--${projectData.name}--${
                assignData.activity.activity
              }`}}):_vm._e(),_vm._l((assignData.sub_assignments),function(subAssignData,subAssignIndex){return [(_vm.readonly && !_vm.profilesModeOn)?_c('SpoilerRow',{key:'profile-' + subAssignData.id + `-${subAssignIndex}`,attrs:{"rowData":{
                  ...subAssignData,
                  name: subAssignData.profile.full_name,
                },"spoilerType":'profile',"spoilerName":`${
                  _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}--` : ''
                }${customerData.name}--${projectData.name}--${
                  assignData.activity.activity
                }--${subAssignData.profile.full_name}`}}):_vm._e(),_vm._l((subAssignData.tasks),function(task){return [(_vm.isTaskVisible(task.id))?_c('ExpensesRow',{key:`${customerData.name}--${projectData.name}--${assignData.activity.activity}--${subAssignData.profile.full_name}--${task.id}`,attrs:{"parentSpoilerName":`${
                    _vm.readonly && _vm.profilesModeOn ? `${profilesData.id}--` : ''
                  }${customerData.name}--${projectData.name}--${
                    assignData.activity.activity
                  }--${subAssignData.profile.full_name}`,"isRowHidden":_vm.expensesHiddenTasks.includes(task.id) &&
                    _vm.isHiddenRowsVisible &&
                    !_vm.readonly}},[_c('ExpensesCell',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.isCurrentIntervalEqualWeek ||
                      _vm.isTaskHasTimeEntries(task)
                    ),expression:"\n                      !isCurrentIntervalEqualWeek ||\n                      isTaskHasTimeEntries(task)\n                    "}],style:({ 'padding-left': '26px' }),attrs:{"numberOfColumn":0,"is-editable":true,"is-general-cell":true,"data":task.name,"taskId":task.id,"isTimeTrackAllowed":true,"timeEntriesList":task.time_entries,"dataOfRestoredTask":_vm.getDataOfRestoredTask(
                        customerData.name,
                        projectData.name,
                        assignData.activity.activity,
                        task.name
                      )},on:{"openDeleteModal":_vm.openDeleteModal}}),_c('ExpensesCell',{directives:[{name:"show",rawName:"v-show",value:(
                      !_vm.isCurrentIntervalEqualWeek ||
                      _vm.isTaskHasTimeEntries(task)
                    ),expression:"\n                      !isCurrentIntervalEqualWeek ||\n                      isTaskHasTimeEntries(task)\n                    "}],staticStyle:{"justify-content":"center"},attrs:{"numberOfColumn":1,"data":_vm.getSumOfTaskHours(task)}}),_vm._l((_vm.intervalData.amount),function(numberOfCell){return [(
                        !_vm.isCurrentIntervalEqualWeek ||
                        _vm.isTaskHasTimeEntries(task)
                      )?_c('ExpensesCell',{key:'cell-number-' +
                        task.id +
                        '_' +
                        numberOfCell +
                        '_' +
                        _vm.getSelectedInterval.amount,attrs:{"part":projectData.part,"is-editable":true,"is-general-cell":false,"data":_vm.getActualHours(
                          task.time_entries,
                          _vm.getDayNumber(numberOfCell - 1)
                        ),"currentTimeEntriesItemId":_vm.getCurrentTimeEntriesId(
                          task.time_entries,
                          _vm.getDayNumber(numberOfCell - 1)
                        ),"taskId":task.id,"pcDayId":_vm.getPCDayId(_vm.getDayNumber(numberOfCell - 1)),"numberOfColumn":numberOfCell + 1,"isItWeekendDay":_vm.isItWeekendDay(numberOfCell),"isTrackedTooMuch":!!_vm.getCurrentTooMuchTrackedDays.length &&
                        !!_vm.getCurrentTooMuchTrackedDays.find(
                          (trackedDay) =>
                            trackedDay.dayId ===
                            _vm.getPCDayId(_vm.getDayNumber(numberOfCell - 1))
                        ),"isItLastCell":numberOfCell === _vm.intervalData.amount,"isTimeTrackAllowed":_vm.isTimeTrackAllowed(numberOfCell),"readonly":_vm.readonly}}):_vm._e()]})],2):_vm._e()]}),(!_vm.readonly)?_c('AddNewTaskRow',{key:'sub-assign-' + subAssignData.id,attrs:{"parentSpoilerName":`${customerData.name}--${projectData.name}--${assignData.activity.activity}`}},[_c('ExpensesCell',{style:({
                    'padding-left': '26px',
                    'background-color': 'rgb(243, 243, 243)',
                  }),attrs:{"isAddCell":true,"onAddNewTaskClick":_vm.addNewTask,"taskInfo":{
                    customerId: customerData.id,
                    projectId: projectData.id,
                    assignId: assignData.id,
                    subAssignId: subAssignData.id,
                  },"data":'+ добавить новую задачу',"numberOfColumn":0}}),_c('ExpensesCell',{attrs:{"is-editable":false,"is-general-cell":true,"numberOfColumn":1,"isItWeekendDay":true}}),_vm._l((_vm.intervalData.amount),function(newTaskNumber){return _c('ExpensesCell',{key:'task-number-' + newTaskNumber,attrs:{"numberOfColumn":newTaskNumber + 1,"isItWeekendDay":true,"is-editable":false,"is-general-cell":false,"isItLastCell":newTaskNumber === _vm.intervalData.amount}})})],2):_vm._e()]})]})]})]})]}),_c('ExpensesControl'),_c('ExpensesColumnResizer')],2):_vm._e(),_c('TooltipCloud'),_c('DeleteModal',{ref:"deleteModal",on:{"confirmDeleteTask":_vm.confirmDeleteTask}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }