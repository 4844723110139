<template>
  <div
    v-if="!getReadonly && getFocusedCellPosition.numberOfColumn >= 0"
    class="expenses__control"
  >
    <div
      v-if="focusedCellPosition.left"
      class="control__vertical-highlight control__vertical-highlight-left"
      :style="{ left: getLeftStyleValue }"
    />
    <div
      v-if="focusedCellPosition.right"
      class="control__vertical-highlight control__vertical-highlight-right"
      :style="{ left: getRightStyleValue }"
    />
    <div
      v-if="focusedCellPosition.top && getFocusedCellPosition.isVisionOn"
      class="control__horizontal-highlight control__horizontal-highlight-top"
      :style="{ top: getTopStyleValue }"
    />
    <div
      v-if="focusedCellPosition.bottom && getFocusedCellPosition.isVisionOn"
      class="control__horizontal-highlight control__horizontal-highlight-bottom"
      :style="{ top: getBottomStyleValue }"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

export default {
  name: "ExpensesControl",
  data() {
    return {
      focusedCellPosition: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
      focusedCellRef: null,
    };
  },
  mounted() {
    setTimeout(() => {
      const options = { capture: true };
      const callback = () => {
        this.calculateStyleValues();
      };
      window.addEventListener("resize", callback, options);
      this.$once("hook:beforeDestroy", () =>
        window.removeEventListener("resize", callback, options)
      );
      this.setCellPosition(this.getFocusedCellPosition);
    }, 100);
  },
  methods: {
    resetFocusedCellPosition() {
      this.focusedCellPosition = {
        top: null,
        bottom: null,
        right: null,
        left: null,
      };
    },
    calculateStyleValues(isOnlyVertical = false) {
      const CONTROL_WIDTH = 1;
      const cellRef = this.focusedCellRef;

      if (!cellRef) {
        return;
      }

      const isHeader = cellRef.hasAttribute("data-col-number");
      const styleLeftValue = cellRef.offsetLeft;
      const styleRightValue =
        cellRef.offsetLeft + cellRef.offsetWidth + CONTROL_WIDTH;
      let styleTopValue = null;
      let styleBottomValue = null;
      if (!isHeader) {
        styleTopValue = cellRef.offsetParent.offsetTop;
        styleBottomValue =
          cellRef.offsetParent.offsetTop + cellRef.offsetParent.offsetHeight;
      }

      this.focusedCellPosition = {
        top: !isOnlyVertical ? styleTopValue : 0,
        bottom: !isOnlyVertical ? styleBottomValue : 0,
        left: styleLeftValue,
        right: styleRightValue,
      };
    },
    setCellPosition(cellPosition) {
      if (cellPosition.isVisionOn && cellPosition.numberOfColumn >= 0) {
        this.setCellRef();
        this.calculateStyleValues(cellPosition.taskId === 0);
      }
    },
    setCellRef() {
      if (this.getFocusedCellPosition.cellRef) {
        this.focusedCellRef = this.getFocusedCellPosition.cellRef;
      } else if (this.getFocusedCellPosition.numberOfColumn) {
        const refSelector = `div[data-col-number='${this.getFocusedCellPosition.numberOfColumn}']`;
        this.focusedCellRef = document.querySelector(refSelector) || null;
      }
    },
  },
  computed: {
    ...mapState({
      resizeStatus: (state) => state.expensesStore.resizeStatus,
      firstColumnWidth: (state) => state.expensesStore.firstColumnWidth,
    }),
    ...mapGetters({
      getReadonly: "expensesStore/getReadonly",
      getFocusedCellPosition: "expensesStore/getFocusedCellPosition",
    }),
    getLeftStyleValue() {
      return this.focusedCellPosition.left
        ? this.focusedCellPosition.left + 1 + "px"
        : 0;
    },
    getRightStyleValue() {
      return this.focusedCellPosition.right
        ? this.focusedCellPosition.right + "px"
        : 0;
    },
    getTopStyleValue() {
      return this.focusedCellPosition.top
        ? this.focusedCellPosition.top + "px"
        : 0;
    },
    getBottomStyleValue() {
      return this.focusedCellPosition.bottom
        ? this.focusedCellPosition.bottom + "px"
        : 0;
    },
  },
  watch: {
    getFocusedCellPosition: {
      handler(newCellPosition) {
        this.setCellPosition(newCellPosition);
      },
      deep: true,
    },
    firstColumnWidth() {
      this.calculateStyleValues();
    },
  },
};
</script>

<style lang="scss" scoped>
.control__vertical-highlight {
  height: calc(100% - 1px);
  width: 1px;
  position: absolute;
  left: 0;
  top: 1px;
  background-color: #6fa8dc;
  z-index: 8;
}

.control__horizontal-highlight {
  height: 1px;
  width: calc(100% - 3px);
  position: absolute;
  left: 1px;
  top: 0;
  background-color: #6fa8dc;
  z-index: 9;
}
</style>
